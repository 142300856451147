import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import TeamService from "../services/TeamService";
import LoginPage from "../pages/Login/LoginPage";
import HomePage from "../pages/Home/HomePage";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import Glossary from "../pages/Glossary/Glossary";
import Updates from "../pages/Updates/Updates";
import MintHere from "../pages/Publish/MintHere";
// import Profiles from "../pages/Publish/Profiles";
import ProfileForm from "../pages/Publish/ProfileForm";
import NewsLetter from "../pages/NewsLetter/NewsLetter";
import Compose_NewsLetter from "../pages/NewsLetter/Compose_NewsLetter";
import Analytics from "../pages/Analytics/Analytics";
import DefaultLayout from "../containers/DefaultLayout";
// import CurrentCourses from "../pages/Learn&Earn/CurrentCourses";
import ManageRewards from "../pages/Learn&Earn/ManageRewards";
// import BuildCourse from "../pages/Learn&Earn/BuildCourse";
import Account from "../pages/Account/Account";
// import Users from "../pages/Users/Users";
import CustomLinks from "../pages/CustomLinks/CustomLinks";
import ClosedTicketList from "../pages/SupportTickets/ClosedTicketList";
import DetailedTicket from "../pages/SupportTickets/DetailedTicket";
import OpenTicketList from "../pages/SupportTickets/OpenTicketList";
import DueSupportTicketList from "../pages/SupportTickets/DueSupportTicketList";
import SupportTicket from "../pages/SupportTickets/SupportTicket";
import SupportTicketform from "../pages/SupportTickets/SupportTicketForm";
import AdminZone from "../pages/AdminZone/AdminZone";
// import ResetPassword from "../pages/ForgotPassword/ForgotPassword";
import UpdatePassword from "../pages/ForgotPassword/ResetPassword";
import Faq from "../pages/Faq/Faq";
import Blogs from "../pages/Blogs/Blogs";
import BlogForm from "../pages/Blogs/BlogForm";
import BlogFormStep2 from "../pages/Blogs/BlogFormStep2";
// import NewsSubscription from "../pages/NewsSubscription/NewsSubscription";
// import NewsSubscriptionForm from "../pages/NewsSubscription/NewsSubscriptionForm";
import Careers from "../pages/Careers/Careers";
import CareerForm from "../pages/Careers/CareerForm";
import Contributers from "../pages/Contributers/Contributers";
import ContributerForm from "../pages/Contributers/ContributerForm";
import Partners from "../pages/Partners/Partners";
import PartnersForm from "../pages/Partners/PartnersForm";
import { GlobalProvider } from "../context/GlobalContext";
import CustomLinkForm from "../pages/CustomLinks/CustomLinkForm";
import Project from "../pages/Project/Project";
import Calendar from "../pages/Calendar/Calendar";
import Task from "../pages/Task/Task";
import Notepad from "../pages/Notepad/Notepad";
import FilesDrive from "../pages/FilesDrive/FilesDrive";
import FinanceDept from "../pages/FinanceDept/FinanceDept";
// import Employees from "../pages/Employees/Employees";
import NewsletterForm from "../pages/Publish/NewsletterForm";
import CourseList from "../pages/Courses/CourseList";
import AddCourse from "../pages/Courses/AddCourse";
import Lesson from "../pages/Courses/Lesson";
import Questions from "../pages/Courses/Questions";
import Review from "../pages/Courses/Review";
// import DraftList from "../pages/Courses/DraftList";
// import FeedbackList from "../pages/Feedback/FeedbackList";
import ForgotPasswordPage from "../pages/ForgotPassword/ForgotPasswordPage";
import AddMeta from "../pages/Courses/Meta";
import Notifications from "../pages/Notifications/Notifications";
import Coins from "../pages/Coins/Coins";
import Advertising from "../pages/Advertising/Advertising";
import GiftCard from "../pages/GiftCard/GiftCard";
import UserDatabase from "../pages/UserDatabase/UserDatabase";
import UserProfile from "../pages/UserDatabase/UserProfile";
import GeneralSetting from "../pages/Settings/GeneralSetting";
import BrandSetting from "../pages/Settings/BrandSetting";
import SliderSetting from "../pages/Settings/SliderSetting";
import ProUsers from "../pages/ProUsers/ProUsers";
import AdvertisingSetting from "../pages/Settings/AdvertisingSetting";
import PaymentSetting from "../pages/Settings/PaymentSetting";
import SeoSetting from "../pages/Settings/SeoSetting";
import BackupSetting from "../pages/Settings/BackupSetting";
import PoliciesSetting from "../pages/Settings/PoliciesSetting";
import NewsletterSetting from "../pages/Settings/NewsletterSetting";
import Video from "../pages/Video/Video";
import VideoForm from "../pages/Video/VideoForm";
import ViewSubscriberList from "../pages/NewsLetter/ViewSubscriberList";
import PublicSubmissions from "../pages/Glossary/PublicSubmissions";
import CourseDraft from "../pages/Courses/CourseDraft";
import SearchKeyword from "../pages/SearchKeyword/SearchKeyword";
import TeamRoles from "../pages/Team&Roles/TeamRoles";
import ChannelList from "../pages/Video/Channel";
import Sitemap from "../pages/Settings/Sitemap";
import AccessDeniedPage from "../pages/ErrorPage/AccessDeniedPage";
import News from "../pages/News/News";
import NewsPublisher from "../pages/News/NewsPublisher";
import BlockListSetting from "../pages/Settings/BlacklistSetting";
import CourseAnalytics from "../pages/Courses/CourseAnalytics";
import BlogAnalytics from "../pages/Blogs/BlogAnalytics";
import Profile from "../pages/Profile/Profile";
function AllRoutes() {
  const [isAuthenticated, setIsAuthenticated] = useState(window.user ? true : false);
  const [roleAccess, setRoleAccess] = useState({
    dashboard: true,
    create_content: true,
    manage_content: true,
    newsletter: true,
    support_ticket: true,
    custom_url: true,
    files_drive: true,
    analytics: true,
    coins: true,
    advertising: true,
    user_database: true,
    pro_users: true,
    gift_cards: true,
    notifications: true,
    team_roles: true,
    panel_settings: true,
  });
  useEffect(() => {
    getUser();
  }, [isAuthenticated]);

  const getUser = () => {
    if (window.user) {
      setIsAuthenticated(true);
    }
  };
  return isAuthenticated && window.user ? (
    <GlobalProvider>
      <DefaultLayout handleAuthState={setIsAuthenticated} handleTeamRole={setRoleAccess}>
        <Routes>
          <Route
            exact
            path="/home"
            // element={<HomePage />}
            element={roleAccess?.dashboard ? <HomePage /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/publish_updates"
            element={roleAccess?.manage_content ? <Updates /> : <Navigate to="/role_restricted" />}
          />
          <Route exact path="/account" element={<Account />} />
          <Route
            exact
            path="/publish_mint"
            element={roleAccess?.manage_content ? <MintHere /> : <Navigate to="/role_restricted" />}
          />
          {/* <Route exact path="/publish_profiles" element={<Profiles />} /> */}
          <Route exact path="/profiles_form" element={<ProfileForm />} />
          <Route
            exact
            path="/publish_glossary"
            element={roleAccess?.manage_content ? <Glossary /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/public_submission"
            element={roleAccess?.manage_content ? <PublicSubmissions /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/giftcard"
            element={roleAccess?.gift_cards ? <GiftCard /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/newsLetter"
            element={roleAccess?.newsletter ? <NewsLetter /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/view_subscriber_list"
            element={roleAccess?.newsletter ? <ViewSubscriberList /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/compose_newsLetter"
            element={roleAccess?.newsletter ? <Compose_NewsLetter /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/compose_newsLetter/:id"
            element={roleAccess?.newsletter ? <Compose_NewsLetter /> : <Navigate to="/role_restricted" />}
          />
          {/* <Route exact path="/publish_newsletter" element={<NewsLetter />} /> */}
          <Route
            exact
            path="/analytics"
            element={roleAccess?.analytics ? <Analytics /> : <Navigate to="/role_restricted" />}
          />
          {/* <Route exact path="/current_courses" element={<CurrentCourses />} /> */}
          {/* <Route exact path="/build_courses" element={<BuildCourse />} /> */}
          {/* <Route exact path="/build_courses/:id" element={<BuildCourse />} /> */}
          <Route
            exact
            path="/course"
            element={roleAccess?.manage_content ? <CourseList /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/course/create_course"
            element={roleAccess?.manage_content ? <AddCourse /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/course/create_course/:id"
            element={roleAccess?.manage_content ? <AddCourse /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/draft_course"
            element={roleAccess?.manage_content ? <CourseDraft /> : <Navigate to="/role_restricted" />}
          />
          {/* <Route exact path="/feedback" element={<FeedbackList />} /> */}
          {/* <Route exact path="/feedback/:id" element={<FeedbackList />} /> */}
          <Route
            exact
            path="/course/create_lesson/:id"
            element={roleAccess?.manage_content ? <Lesson /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/course/course_analytics/:id"
            element={roleAccess?.manage_content ? <CourseAnalytics /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/blogs/blogs_form/:id"
            element={roleAccess?.manage_content ? <BlogForm /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/blogs/blogs_form_step2/:id"
            element={roleAccess?.manage_content ? <BlogFormStep2 /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/blogs/blogs_analytics/:id"
            element={roleAccess?.manage_content ? <BlogAnalytics /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/course/create_question/:id"
            element={roleAccess?.manage_content ? <Questions /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/course/create_meta/:id"
            element={roleAccess?.manage_content ? <AddMeta /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/course/review/:id"
            element={roleAccess?.manage_content ? <Review /> : <Navigate to="/role_restricted" />}
          />
          {/* <Route exact path="/draft" element={<DraftList />} /> */}
          <Route
            exact
            path="/course/create_course/:id/:draftid"
            element={roleAccess?.manage_content ? <AddCourse /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/course/create_lesson/:id/:draftid"
            element={roleAccess?.manage_content ? <Lesson /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/blogs/blogs_form_step2/:id/:draftid"
            element={roleAccess?.manage_content ? <BlogFormStep2 /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/course/create_question/:id/:draftid"
            element={roleAccess?.manage_content ? <Questions /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/course/create_meta/:id/:draftid"
            element={roleAccess?.manage_content ? <AddMeta /> : <Navigate to="/role_restricted" />}
          />
          <Route
            exact
            path="/course/review/:id/:draftid"
            element={roleAccess?.manage_content ? <Review /> : <Navigate to="/role_restricted" />}
          />
          <Route exact path="/manage_reward_store" element={<ManageRewards />} />
          {/* <Route exact path="/user_table" element={<Users />} /> */}
          <Route
            exact
            path="/custom_link"
            element={!roleAccess?.custom_url ? <Navigate to="/role_restricted" /> : <CustomLinks />}
          />
          <Route
            exact
            path="/custom_link_form"
            element={!roleAccess?.custom_url ? <Navigate to="/role_restricted" /> : <CustomLinkForm />}
          />
          <Route
            exact
            path="/newsletter_form"
            element={!roleAccess?.newsletter ? <Navigate to="/role_restricted" /> : <NewsletterForm />}
          />
          <Route
            exact
            path="/newsletter_form/:id"
            element={!roleAccess?.newsletter ? <Navigate to="/role_restricted" /> : <NewsletterForm />}
          />
          <Route
            exact
            path="/custom_linkform/:id"
            element={!roleAccess?.custom_url ? <Navigate to="/role_restricted" /> : <CustomLinkForm />}
          />
          <Route
            exact
            path="/newsletter_form/:id"
            element={!roleAccess?.newsletter ? <Navigate to="/role_restricted" /> : <NewsletterForm />}
          />
          <Route
            exact
            path="/support_ticket"
            element={!roleAccess?.support_ticket ? <Navigate to="/role_restricted" /> : <SupportTicket />}
          />
          <Route
            exact
            path="/closedTicketList"
            element={!roleAccess?.support_ticket ? <Navigate to="/role_restricted" /> : <ClosedTicketList />}
          />
          <Route
            exact
            path="/detailedTicket/:id"
            element={!roleAccess?.support_ticket ? <Navigate to="/role_restricted" /> : <DetailedTicket />}
          />
          <Route
            exact
            path="/openTicketList"
            element={!roleAccess?.support_ticket ? <Navigate to="/role_restricted" /> : <OpenTicketList />}
          />
          <Route
            exact
            path="/dueSupportTicketList"
            element={!roleAccess?.support_ticket ? <Navigate to="/role_restricted" /> : <DueSupportTicketList />}
          />
          <Route exact path="/admin_zone" element={<AdminZone />} />
          <Route exact path="/error" element={<ErrorPage />} />
          <Route
            exact
            path="/faq"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <Faq />}
          />
          <Route
            exact
            path="/filesDrive"
            element={!roleAccess?.files_drive ? <Navigate to="/role_restricted" /> : <FilesDrive />}
          />
          <Route exact path="/coins" element={!roleAccess?.coins ? <Navigate to="/role_restricted" /> : <Coins />} />
          <Route
            exact
            path="/advertising"
            element={!roleAccess?.advertising ? <Navigate to="/role_restricted" /> : <Advertising />}
          />
          <Route
            exact
            path="/userDatabase"
            element={!roleAccess?.user_database ? <Navigate to="/role_restricted" /> : <UserDatabase />}
          />
          <Route
            exact
            path="/userDatabaseProfile/:id"
            element={!roleAccess?.user_database ? <Navigate to="/role_restricted" /> : <UserProfile />}
          />
          <Route
            exact
            path="/proUsers"
            element={!roleAccess?.pro_users ? <Navigate to="/role_restricted" /> : <ProUsers />}
          />
          <Route
            exact
            path="/notifications"
            element={!roleAccess?.notifications ? <Navigate to="/role_restricted" /> : <Notifications />}
          />
          <Route
            exact
            path="/blogs"
            // element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <Blogs />}
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <Blogs />}
          />
          <Route
            exact
            path="/blogs_form"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <BlogForm />}
          />
          <Route
            exact
            path="/blogs_form/:id"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <BlogForm />}
          />
          <Route
            exact
            path="/supportTiket_form/:id"
            element={!roleAccess?.support_ticket ? <Navigate to="/role_restricted" /> : <SupportTicketform />}
          />
          <Route
            exact
            path="/supportTiket_form"
            element={!roleAccess?.support_ticket ? <Navigate to="/role_restricted" /> : <SupportTicketform />}
          />
          {/* <Route
            exact
            path="/newssubscription"
            element={<NewsSubscription />}
          /> */}
          {/* <Route
            exact
            path="/subscription_form/:id"
            element={<NewsSubscriptionForm />}
          />
          <Route
            exact
            path="/subscription_form"
            element={<NewsSubscriptionForm />}
          /> */}
          <Route
            exact
            path="/careers"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <Careers />}
          />
          <Route
            exact
            path="/careers_form"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <CareerForm />}
          />
          <Route
            exact
            path="/careers_form/:id"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <CareerForm />}
          />
          <Route
            exact
            path="/contributers"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <Contributers />}
          />
          <Route
            exact
            path="/contributers_form"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <ContributerForm />}
          />
          <Route
            exact
            path="/contributers_form/:id"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <ContributerForm />}
          />
          <Route exact path="/calendar" element={<Calendar />} />
          <Route exact path="/projects" element={<Project />} />
          <Route exact path="/task" element={<Task />} />
          <Route
            exact
            path="/partners"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <Partners />}
          />
          <Route
            exact
            path="/partners_form"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <PartnersForm />}
          />
          <Route
            exact
            path="/partners_form/:id"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <PartnersForm />}
          />
          <Route exact path="/notepad" element={<Notepad />} />
          <Route
            exact
            path="/files_drive"
            element={!roleAccess?.files_drive ? <Navigate to="/role_restricted" /> : <FilesDrive />}
          />
          <Route exact path="/finance" element={<FinanceDept />} />

          <Route
            exact
            path="/team_roles"
            element={!roleAccess?.team_roles ? <Navigate to="/role_restricted" /> : <TeamRoles />}
          />
          <Route
            exact
            path="/setting/general"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <GeneralSetting />}
          />
          <Route
            exact
            path="/setting/blocklist"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <BlockListSetting />}
          />
          <Route
            exact
            path="/setting/sitemap"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <Sitemap />}
          />
          <Route
            exact
            path="/setting/brand"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <BrandSetting />}
          />
          <Route
            exact
            path="/setting/slider"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <SliderSetting />}
          />
          <Route
            exact
            path="/setting/advertising"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <AdvertisingSetting />}
          />
          <Route
            exact
            path="/setting/payment"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <PaymentSetting />}
          />
          <Route
            exact
            path="/setting/seo"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <SeoSetting />}
          />
          <Route
            exact
            path="/setting/backup"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <BackupSetting />}
          />
          <Route
            exact
            path="/setting/policies"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <PoliciesSetting />}
          />
          <Route
            exact
            path="/setting/newsletter"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <NewsletterSetting />}
          />
          <Route
            exact
            path="/video"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <Video />}
          />
          <Route
            exact
            path="/news"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <News />}
          />
          <Route
            exact
            path="/news_publisher"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <NewsPublisher />}
          />
          <Route
            exact
            path="/video/video_form"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <VideoForm />}
          />
          <Route
            exact
            path="/video/video_form/:id"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <VideoForm />}
          />
          <Route
            exact
            path="/setting/newsletter"
            element={!roleAccess?.panel_settings ? <Navigate to="/role_restricted" /> : <NewsletterSetting />}
          />
          <Route exact path="/search_keyword" element={<SearchKeyword />} />
          <Route
            exact
            path="/view_channel_list"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <ChannelList />}
          />
          <Route
            exact
            path="/profile"
            element={!roleAccess?.manage_content ? <Navigate to="/role_restricted" /> : <Profile />}
          />
          <Route exact path="role_restricted" element={<AccessDeniedPage />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </DefaultLayout>
    </GlobalProvider>
  ) : (
    <Routes>
      <Route exact path="/login" element={<LoginPage handleAuthState={setIsAuthenticated} />} />
      <Route exact path="/forgot_password" element={<ForgotPasswordPage />} />
      <Route exact path="/reset/:token" element={<UpdatePassword />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default AllRoutes;
