import ListNew from "../../shared/List/ListNew";
import FileDriveService from "../../services/FileDriveService";
import moment from "moment";
import download from "downloadjs";
import { useNavigate } from "react-router-dom";
import AddFile from "./AddFile";
import { Link } from "react-router-dom";
import { Action } from "@remix-run/router";
import { useState, useEffect } from "react";
import { FileStatus } from "../../context/FileStatusContext";
const convertToDate = (value, index) => {
  return value && value.createdAt ? moment(value.createdAt).format("DD/MM/YYYY") : "";
};
const getFileExtenstion = (value, index) => {
  return value && value.file ? (
    <div className="fileRowSize">
      <p className="mt-1 mb-2 textAlignLeft">{value.name}</p>
      <p className="marginBottom0 textAlignLeft">{value.file?.split(".").pop()}</p>
    </div>
  ) : (
    ""
  );
};
const getImgPrev = (value, index) => {
  return value && value.type == 1 ? (
    <a href={value.file} target="_blank">
      <img src={value.file} className="filePrevImg" />
    </a>
  ) : value && value.type == 2 && value.banner_img ? (
    <a href={value.banner_img} target="_blank">
      <img src={value.banner_img} className="filePrevImg" />
    </a>
  ) : (
    <img src="images/svg-icons/Rectangle_shapimg.svg" className="filePrevImg" />
  );
};
const getMemoryUnit = (value, index) => {
  return value && value.size < 1024 * 1024 ? (
    <>{Math.round(value.size / 1024)} KB</>
  ) : (
    <>{Math.round(value.size / 1024 / 1024)} MB</>
  );
};
const columns = [
  { label: "Preview", key: "file", valueFunc: getImgPrev, cssTh: "fileListhead fileCol1th", cssTd: "" },
  {
    label: "File Name",
    key: "file",
    valueFunc: getFileExtenstion,
    cssTh: "fileListhead fileCol2th",
    cssTd: "paddingLeft0",
  },
  { label: "Size", key: "size", valueFunc: getMemoryUnit, cssTh: "fileListhead  fileSizeTd", cssTd: "paddingTop32" },
  {
    label: "Date Added",
    key: "createdAt",
    valueFunc: convertToDate,
    cssTh: "fileListhead  fileDate",
    cssTd: "paddingTop32",
  },
  { label: "Actions", key: "ACTIONS", cssTh: "fileListhead fileAction ", cssTd: "paddingTop32" },
];
const sortByOptions = [{ key: "createdAt--desc", value: "Recently Added" }];

function FilesDrive() {
  const navigate = useNavigate();
  const fileServ = new FileDriveService();
  const [totalMemory, setTotalMemory] = useState(0);
  useEffect(() => {
    getTotalMemory();
  });
  const getTotalMemory = async () => {
    try {
      let response = await fileServ.totalMemory({});
      setTotalMemory(response.totalMemory);
      console.log(response.totalMemory);
    } catch (error) {
      console.log(error);
    }
  };
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        folder_id: "63f5b046bc7f1dc87efe88c1",
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "careers"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await fileServ.listAll(activity);
      response.data = response.data.map((rec) => {
        rec.category_id = rec.category_id?._id ? rec.category_id.name : "";
        return rec;
      });
      response.data = response.data.map((rec) => {
        rec.author = rec.author?._id ? rec.author.user_name : "";
        return rec;
      });
      if (response) {
        setList(response.data);
        setTotalCount(response.total);
        getTotalMemory();
      }
    } catch (err) {
      throw err;
    }
  }
  const downloadHandler = async (filePath) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ path: filePath }),
      };
      let token = window.user ? window.user.token : "no-token";
      requestOptions.headers["Authorization"] = "Bearer " + token;
      const res = await fetch(process.env.REACT_APP_API_BASEURL + "/filesdrive/download", requestOptions);
      const blob = await res.blob();
      download(blob, filePath.split("/").pop());
    } catch (err) {
      return false;
    }
  };
  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await fileServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
  const activeToggleHandler = async (careerObj) => {
    let obj = {
      _id: careerObj._id,
      is_active: !careerObj.is_active,
    };
    try {
      let response = await fileServ.updateRecord(obj);
      if (response?.data?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  const viewHandler = async (id) => {
    // console.log(id)
  };
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="d-flex justify-content-between fileDriveTop">
        <p className="marginBottom0">File Drive</p>
        <div>
          <button>
            <img src="/images/svg-icons/fileStoreIcon.svg" className="filedickIconSpacing" />
            Disk Space Used: {totalMemory} GB
          </button>
        </div>
      </div>
      <FileStatus>
        <ListNew
          placeholder="Search files"
          buttonText="Upload Files"
          columns={columns}
          getListDataFunc={getListData}
          deleteHandlerFunc={deleteHandler}
          downloadHandlerFunc={downloadHandler}
          activeToggleHandlerFunc={activeToggleHandler}
          sortByOptions={sortByOptions}
          PopupComponent={AddFile}
          fileDriveCss={true}
          topRadius0={true}
          fileDrive={true}
          viewHandlerFuncFile={viewHandler}
        />
      </FileStatus>
      {/* <ListNew/> */}
    </div>
  );
}

export default FilesDrive;
