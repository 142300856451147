import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import CourseService from "../../services/CourseService";
import ConfirmDeletePopup from "../../shared/ConfirmDeletePopup/ConfirmDeletePopup";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  ArcElement,
  elements,
} from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Legend);
function CourseAnalytics() {
  const courseServ = new CourseService();
  const [courseDetails, setCourseDetails] = useState();
  const [analyticsDetails, setAnalyticsDetails] = useState();
  const [showDeletePop, setShowDeletePop] = useState(false);
  const [notes, setNotes] = useState("");
  const [yearlyViews, setYearlyViews] = useState();
  const [yearlyusers, setYearlyUsers] = useState();
  const [yearlyShare, setYearlyShare] = useState();
  const [usersTypes, setUsersTypes] = useState();
  const [emptyGraphMsgView, setEmptyGraphMsgView] = useState("");
  const [emptyGraphMsgUser, setEmptyGraphMsgUser] = useState("");
  const [emptyGraphMsgShare, setEmptyGraphMsgShare] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    getCouseDetails(params.id);
    getCourseAnalytics(params.id);
    getYearlyViews(params.id);
    getYearlyUsers(params.id);
    getUsersTypes(params.id);
    getYearlyShares(params.id)
  }, []);
  const getUsersTypes = async () => {
    try {
      let response = await courseServ.getUsersTypes({ courseId: params?.id });
      setUsersTypes(response);
    } catch (error) {
      console.log(error);
    }
  };
  const getYearlyViews = async () => {
    try {
      let response = await courseServ.getYearlyViews({ courseId: params?.id });
      setYearlyViews(response);
      let totalUniqueCount = 0;
      for (let i = 0; i < response.length; i++) {
        totalUniqueCount = totalUniqueCount + response[i].uniqueCount;
      }
      setEmptyGraphMsgView(totalUniqueCount);
    } catch (error) {
      console.log(error);
    }
  };
  const getYearlyUsers = async () => {
    try {
      let response = await courseServ.getYearlyUsers({ courseId: params?.id });
      setYearlyUsers(response);
      let totalUniqueCount = 0;
      for (let i = 0; i < response.length; i++) {
        totalUniqueCount = totalUniqueCount + response[i].uniqueCount;
      }
      setEmptyGraphMsgUser(totalUniqueCount);
    } catch (error) {
      console.log(error);
    }
  };
  const getYearlyShares = async () => {
    try {
      let response = await courseServ.getYearlyShares({ courseId: params?.id });
      setYearlyShare(response);
      let totalUniqueCount = 0;
      for (let i = 0; i < response.length; i++) {
        totalUniqueCount = totalUniqueCount + response[i].uniqueCount;
      }
      setEmptyGraphMsgShare(totalUniqueCount);
    } catch (error) {
      console.log(error);
    }
  };
  const getCourseAnalytics = async () => {
    try {
      let response = await courseServ.getAnalytics({ courseId: params?.id });
      setAnalyticsDetails(response);
    } catch (error) {
      console.log(error);
    }
  };
  const getCouseDetails = async () => {
    try {
      let response = await courseServ.getCourse(params?.id);
      if (response.data) {
        setCourseDetails(response);
        setNotes(response.data.notes);
      } else {
      }
    } catch (err) {
      throw err;
    }
  };
  const activeToggleHandlerFunc = async (course) => {
    try {
      let isActive = !course.is_active;
      let obj = {
        is_active: isActive,
        _id: course._id,
      };
      let res = await courseServ.activeToggle(obj);
      if (res?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  const activeToggleHandler = async (obj, index) => {
    let changed = await activeToggleHandlerFunc(obj);
    if (changed) {
      toast.success(changed.message);
      getCouseDetails(params.id);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const deleteHandler = async () => {
    try {
      if (true) {
        let response = await courseServ.deleteCourse(params.id);
        if (response) {
          navigate("/course");
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
  const onNotesSubmit = async (value) => {
    let payload = {
      _id: params.id,
      notes: value,
    };
    try {
      let response = await courseServ.editCourse(payload);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const yearlyViewsdata = {
    labels: yearlyViews?.map((v, i) => {
      return v.year_month;
    }),
    datasets: [
      {
        data: yearlyViews?.map((v, i) => {
          return v.count;
        }),
        backgroundColor: [
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          // " #29 263238",
        ],
      },
    ],
  };
  const yearlyUsersdata = {
    labels: yearlyusers?.map((v, i) => {
      return v.year_month;
    }),
    datasets: [
      {
        data: yearlyusers?.map((v, i) => {
          return v.count;
        }),
        backgroundColor: [
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          // " #29 263238",
        ],
        barThickness : 17,
      },
    ],
  };
  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 1,
        borderRadius: 4,
        borderColor:"gray",
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const yearlyShareData = {
    labels: yearlyShare?.map((v, i) => {
      return v.year_month;
    }),
    datasets: [
      {
        data: yearlyShare?.map((v, i) => {
          return v.count;
        }),
        backgroundColor: [
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          " #2B4EBE",
          // " #29 263238",
        ],
      },
    ],
  };
  const pieData = {
    datasets: [
      {
        data: [usersTypes?.existingUser, usersTypes?.newUser, usersTypes?.otherUser],
        backgroundColor: ["#2B4EBE", "#FFBE0B", "#4FD939"],
        borderWidth: 0,
      },
    ],
  };
  const pieOption = {};
  return (
    <div className="CourseAnalyticsDiv">
      <div className="analyticsHeader bBottom">
        <div className="d-flex">
          <div className="analyticsbackBtn" onClick={() => navigate("/course")}>
            <img src="/images/icons/back-icon.svg" />
          </div>
          <div className="courseAnalyticsTitle ">
            <div className="d-flex">
              <div>
                <img src={courseDetails?.data?.cover_image} />
              </div>
              <div>
                <p className="analyticsTitle m-0">{courseDetails?.data?.course_name}</p>
                <p className="authorName m-0">Author: {courseDetails?.author.full_name}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex borderT">
          <div className="publishedOnDiv">
            <p className="publishedonP1">Published On</p>
            <p className="m-0 publishedonP2">{moment(courseDetails?.createdAt).format("DD/MM/YYYY")}</p>
          </div>
          <div className={"statusDiv " + (!courseDetails?.data?.is_active ? " padding17_0" : " ")}>
            <p className="publishedonP1">Status</p>
            <div className="tbl_status d-flex align-items-center justify-content-center">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={courseDetails?.data?.is_active}
                  onChange={() => activeToggleHandler(courseDetails?.data)}
                />
                <span className="slider round " style={{ width: "30px", marginRight: "10px" }} />
              </label>
              {courseDetails?.data?.is_active ? <label>Active</label> : <label>Inactive</label>}
            </div>
          </div>
          <div className="actionDiv">
            <p className="publishedonP1">Action</p>
            <p className="publishedonP2 mb-0">
              <img
                onClick={() => navigate("/course/create_course/" + params.id)}
                src="/images/svg-icons/editActionIcon.svg"
              />
              <a href={process.env.REACT_APP_FRONT_END_BASEURL + "/courses/info/" + params.id} target="blank">
                <img src="/images/svg-icons/viewActionIcon.svg" className="actionViewMargin" />
              </a>

              <img onClick={() => setShowDeletePop(true)} src="/images/svg-icons/deleteActionIcon.svg" />
            </p>
          </div>
        </div>
      </div>
      <div className="courseAnalyticsMid">
        <h2>Course Analytics</h2>
        <div className="analyticsGridBox">
          <div className="anylyticsCard">
            <div className="d-flex justify-content-between cardLi">
              <p>Total Unique Views:</p>
              <h6>{analyticsDetails?.uniqueViews}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Total Unique Users:</p>
              <h6>{analyticsDetails?.uniqueUsers}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Total Unique Shares:</p>
              <h6>{analyticsDetails?.uniqueShares}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi borderNone paddingBottom0">
              <p>Total Current Bookmarks:</p>
              <h6>{analyticsDetails?.currBookmarks}</h6>
            </div>
          </div>
          <div className="anylyticsCard">
            <div className="d-flex justify-content-between cardLi">
              <p>Total # of times Course Completed:</p>
              <h6>{analyticsDetails?.completeCourse}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Total # of times Rewards Claimed:</p>
              <h6>{analyticsDetails?.rewardClaimedCount}</h6>
            </div>
            
            <div className="d-flex justify-content-between cardLi">
              <p>Total Pass (1st attempt):</p>
              <h6>{analyticsDetails?.totalPass}</h6>
            </div>
            <div className="d-flex justify-content-between borderNone cardLi paddingBottom0">
              <p>Total Fail (1st attempt):</p>
              <h6>{analyticsDetails?.totalFail}</h6>
            </div>
          </div>
          <div className="anylyticsCard ">
          <div className="d-flex justify-content-between cardLi">
              <p>Total Reward Coins Claimed:</p>
              <h6>{analyticsDetails?.totalReward}</h6>
            </div>
            <div className="d-flex justify-content-between cardLi  ">
              <p>Title:</p>
              <h6>000</h6>
            </div>
            <div className="d-flex justify-content-between cardLi">
              <p>Title:</p>
              <h6>Text</h6>
            </div>
            <div className="d-flex justify-content-between cardLi borderNone paddingBottom0">
              <p>Title:</p>
              <h6>Text</h6>
            </div>
          </div>
        </div>
        <div className="analyticsGraphGrid">
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Unique Views</p>
              <h6>Last 12 months</h6>
            </div>
            <div className="barPadding">
              {emptyGraphMsgView == 0 ? (
                <p className="text-center">no data found</p>
              ) : (
                <Bar data={yearlyViewsdata} height={100}  options={options} />
              )}
            </div>
          </div>
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Unique Users</p>
              <h6>Last 12 months</h6>
            </div>
            <div className="barPadding">
              {emptyGraphMsgUser == 0 ? (
                <p className="text-center">no data found</p>
              ) : (
                <Bar data={yearlyUsersdata} height={100}  options={options} />
              )}
            </div>
          </div>
          <div className="graphCard">
            <div className="graphCardNav">
              <p>Shares</p>
              <h6>Last 12 months</h6>
            </div>
            <div className="barPadding">
            {emptyGraphMsgShare == 0 ? (
                <p className="text-center">no data found</p>
              ) : (
                <Bar data={yearlyShareData} height={100}  options={options} />
              )}
            </div>
          </div>
          <div className="graphCard" style={{ height: "200px" }}>
            <div className="graphCardNav">
              <p>Types of Users</p>
              <h6>All-time</h6>
            </div>
            <div className="row">
              <div className="col-6 pieChartLabel">
                <div className="d-flex">
                  <div className="circle1"></div>{" "}
                  <div>
                    Existing: <span>{Math.round(usersTypes?.existingUser)}%</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="circle2"></div>{" "}
                  <div>
                    New: <span>{Math.round(usersTypes?.newUser)}%</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="circle3"></div>{" "}
                  <div>
                    Other: <span>{Math.round(usersTypes?.otherUser)}%</span>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <Doughnut data={pieData} options={pieOption} width={100} height={0} />
              </div>
            </div>
          </div>
        </div>
        <div className="notesDiv" style={{ height: "265px" }}>
          <p>Notes</p>
          <textarea
            placeholder="Start typing..."
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
              onNotesSubmit(e.target.value);
            }}
          />
        </div>
      </div>
      <ToastContainer />
      {showDeletePop && (
        <ConfirmDeletePopup
          onSucess={deleteHandler}
          onClose={() => {
            setShowDeletePop(false);
          }}
        />
      )}
    </div>
  );
}

export default CourseAnalytics;
