import { Link } from "react-router-dom";
export default function Steps({ step, blogId }) {
  if (!blogId) {
    return <></>;
  }
  return (
    <ul className="nav">
      <li className="nav-item">
        <Link
          className={"nav-link" + (step == 1 ? " active" : "")}
          to={"/blogs/blogs_form/" + blogId}
        >
          1
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={"nav-link" + (step == 2 ? " active" : "")}
          to={"/blogs/blogs_form_step2/" + blogId}
        >
          2
        </Link>
      </li>
    </ul>
  );
}