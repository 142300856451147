import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import BlogService from "../../services/blogService";
import Steps from "./Steps";
import TagsInput from "../Courses/TagsInput";

function BlogFormStep2() {
  const params = useParams();
  const router = useNavigate();
  const blogServ = new BlogService();
  const [blog, setBlog] = useState({
    _id: "",
    metadata: { title: "", tags: "", description: "" },
  });
  const [showLoading, setShowLoading] = useState(true);
  const [defaultTag, setDefaultTag] = useState([]);
  useEffect(() => {
    if (params?.id) {
      onLoadBlogData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const onLoadBlogData = async () => {
    try {
      let response = await blogServ.getDetails(params?.id);
      if (response.data) {
        setBlog({
          metadata: { title: "", tags: "", description: "" },
          ...response.data,
        });
        let defaultTag = [];
        response.data.metadata.tags.split(",").forEach((item) => {
          let obj = { value: item, label: item };
          defaultTag = [...defaultTag, obj];
        });
        if(response.data.metadata.tags!=""){
          setDefaultTag([...defaultTag]);
        }
      } else {
        toast.error(response?.error);
      }
    } catch (err) {
      throw err;
    }
  };

  async function fetchDraftList() {
    if (params?.draftid !== "") {
      let response = await blogServ.getDraftDetails(params?.draftid);
      if (response.data) {
        let blogObj = response.data;
        let draftObj = {
          ...blogObj,
          _id: response.data.blog_id,
        };
        delete draftObj.blog_id;
        setBlog(draftObj);
      }
    }
  }

  const onSubmit = async (formValues) => {
    setShowLoading(true);
    let values = { ...formValues };
    try {
      if (values._id !== undefined && values._id !== "") {
        const formData = new FormData();
        formData.append("_id", values._id);
        formData.append("metadata", JSON.stringify(values.metadata));
        const token = window.user ? window.user.token : "no-token";
        const config = {
          headers: {
            content: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        };
        axios.put(process.env.REACT_APP_API_BASEURL + "/blog", formData, config).then(async (response) => {
          if (response.data) {
            toast.success("Blog updated successfully");
            window.scroll(0, 0);
            setTimeout(() => {
              router("/blogs");
            }, 1500);
          }
        });
      } else {
        const formData = new FormData();
        formData.append("_id", values._id);
        formData.append("meta", values.meta);
        const token = window.user ? window.user.token : "no-token";
        const config = {
          headers: {
            content: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        };
        axios.post(process.env.REACT_APP_API_BASEURL + "/blog", formData, config).then(async (response) => {
          if (response.data) {
            window.scroll(0, 0);
            toast.success("Course added successfully");
            setTimeout(() => {
              if (params.draftid) {
                router(`/blogs/blog_form_step2/${response.data?.data?._id}/${params.draftid}`);
              } else {
                router(`/blogs/blog_form_step2/${response.data?.data?._id}`);
              }
            }, 1000);
          }
        });
      }
    } catch (err) {
      throw err;
    }
    setShowLoading(false);
  };
  const formik = useFormik({
    initialValues: blog,
    validateOnBlur: true,
    onSubmit,
    enableReinitialize: true,
  });
  const callBackTags = (tags) => {
    let stringTag = tags.join(",")
    formik.setFieldValue("metadata.tags", stringTag);
  };
  return (
    <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
      <div className="commonCart account_page">
        <div className="stepFormHead d-flex align-items-center">
          <div className="leftBox order-1">
            <div className="backIconBtn">
              <Link to="/blogs">
                <img src="/images/icons/back-icon.svg" alt="backicon" className="img-fluid" />
              </Link>
            </div>
          </div>
          <div className="rightBox order-2 order-sm-3">
            <h5>Step 2</h5>
            <h6>SEO</h6>
          </div>
          <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
            <div className="createCourseHeading">
              <h4>
                <img src="/images/icons/blog.svg" alt="backicon" className="img-fluid" /> Post a Blog Article
              </h4>
            </div>
            <div className="paginationNav">
              <Steps step={2} blogId={params?.id} />
            </div>
          </div>
        </div>
        <div className="userProfile_details">
          <div className="createcourseStep2 comPadding">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="mb-4 account_input">
                    <label htmlFor="pageTitle" className="form-label">
                      Page Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pageTitle"
                      placeholder="Please enter the page title"
                      name="metadata.title"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metadata.title}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4 account_input">
                    <label htmlFor="pageTag" className="form-label">
                      Page Tags
                    </label>
                    <TagsInput
                      callBackTags={callBackTags}
                      tags={formik.values.tags}
                      formik={formik}
                      // isSearchable
                      // prevTags={tagList}
                      defualtTag={defaultTag}
                      hideMenu={true}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4 account_input modelFormInput">
                    <label htmlFor="pageDescription" className="form-label">
                      Page Description
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Please enter the page description"
                      rows={7}
                      name="metadata.description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metadata.description}
                    />
                  </div>
                </div>
              </div>
              <div className="commonform_btn d-grid col-12">
                <button type="submit" className="btn btn-primary btn-block ">
                  {!showLoading ? (
                    <>
                      <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                    </>
                  ) : (
                    <>Publish</>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogFormStep2;
